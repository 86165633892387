.chat-area {
    padding: 10px;
}

.chat-message {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.chat-message.user {
    background-color: #e0e0e0;
    color: black;
    padding: 10px;
    border-radius: 5px;
}

.chat-message.ai {
    background-color: #f4f6f9;
    color: black;
    padding: 10px;
    border-radius: 5px;
}

.chat-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.chat-message.user .chat-icon {
    background-color: #007bff;
}

.chat-message.ai .chat-icon {
    background-color: #6c757d;
}

.chat-text {
    flex: 1;
}

.clickable-text {
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
}

.clickable-text:hover {
    text-decoration: none;
}

.no-sidebar.content-wrapper {
  margin-left: 0 !important;
}

